import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import PurchaseTable from './Components/PurchaseTable';
import PurchaseSumCard from './Components/PurchaseSumCard';
import TimeShortcuts from 'components/TimeShortcuts';
import { useState } from 'react';
import CouponPoolsCountTable from './Components/CouponsPoolsCountTable';
import { Subtitle } from '../../components/helpers/chartHelpers';
import ActivationCodesCountTable from './Components/ActivationCodesCountTable';

function PurchasePage() {
  const [shortcutFrom, setShortcutFrom] = useState(null);
  const [shortcutTo, setShortcutTo] = useState(null);

  return (
    <div>
      <GridContainer spacing={3} style={{ padding: 20 }}>
        <GridItem xs={2}>
          <TimeShortcuts
            setTimeTo={setShortcutTo}
            setTimeFrom={setShortcutFrom}
          />
        </GridItem>
        <GridItem xs={10}>
          <PurchaseTable shortcutFrom={shortcutFrom} shortcutTo={shortcutTo} />
        </GridItem>
        <GridItem xs={12}>
          <PurchaseSumCard />
        </GridItem>
        <GridItem xs={2} />
        <GridItem xs={10}>
          <Subtitle color="#212121">Wykorzystanie puli kuponów</Subtitle>
        </GridItem>
        <GridItem xs={2} />
        <GridItem xs={10}>
          <CouponPoolsCountTable
            shortcutFrom={shortcutFrom}
            shortcutTo={shortcutTo}
          />
        </GridItem>
        <GridItem xs={2} />
        <GridItem xs={10}>
          <Subtitle color="#212121">
            Wykorzystanie kuponów aktywacji subskrypcji
          </Subtitle>
        </GridItem>
        <GridItem xs={2} />
        <GridItem xs={10}>
          <ActivationCodesCountTable
            shortcutFrom={shortcutFrom}
            shortcutTo={shortcutTo}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
export default PurchasePage;
