import React, { useState, useEffect } from 'react';
import { Filters } from '../../../components/helpers/chartHelpers';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import { inject, observer } from 'mobx-react';
import * as PropTypes from 'prop-types';
import CustomLineChart from '../../../components/CustomLineChart/CustomLineChart';
import CustomSelect from './CustomSelect';
import moment from 'moment';
import { currencyFormatter } from '../../../components/helpers/utils';

const colors = ['#272727', '#868686'];
const keys = ['value'];
const keysTranslation = ['ilość transakcji'];
const xAxis = 'fromTs';

const PaymentLineChart = ({
  personaStore: {
    paymentsLineChartData,
    availableMasks,
    paymentTypes,
    paymentProviders,
    fetchPaymentsLineChartData,
    fetchMasks,
    fetchPaymentTypes,
    fetchPaymentProviders,
    loadingPaymentsLineChartData,
    loadingAvailableMasks,
    loadingPaymentTypes,
    loadingPaymentProviders,
    clearPaymentsLineChartData,
  },
  shortcutFrom,
  shortcutTo,
}) => {
  const [startDate, setStartDate] = useState(
    moment().subtract(30, 'day').startOf('day').toDate(),
  );
  const [endDate, setEndDate] = useState(moment().endOf('day').toDate());
  const [paymentType, setPaymentType] = useState();
  const [paymentProvider, setPaymentProvider] = useState(null);
  const [mask, setMask] = useState('four_fstore_pl');

  useEffect(() => {
    setPaymentType(null);
    setPaymentProvider(null);
    clearPaymentsLineChartData();
  }, [mask]);

  useEffect(() => {
    fetchMasks(startDate, endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    if (mask) {
      fetchPaymentProviders(startDate, endDate, mask, paymentType);
      setPaymentType('BLIK');
    }
  }, [paymentType, mask]);

  useEffect(() => {
    if (mask) {
      fetchPaymentTypes(startDate, endDate, mask, paymentProvider);
    }
  }, [paymentProvider, mask]);

  useEffect(() => {
    if (shortcutFrom) {
      setStartDate(shortcutFrom);
    }
    if (shortcutTo) {
      setEndDate(shortcutTo);
    }
  }, [shortcutFrom, shortcutTo]);

  useEffect(() => {
    if ((paymentType || paymentProvider) && mask) {
      fetchPaymentsLineChartData(
        startDate,
        endDate,
        mask,
        paymentType,
        paymentProvider,
      );
    } else {
      clearPaymentsLineChartData();
    }
  }, [startDate, endDate, paymentType, paymentProvider, mask]);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <CustomSelect
          value={mask}
          options={availableMasks}
          loading={loadingAvailableMasks}
          setValue={setMask}
          placeholder="Wybierz maskę..."
        />
        <CustomSelect
          value={paymentType}
          options={paymentTypes}
          loading={loadingPaymentTypes}
          setValue={setPaymentType}
          placeholder="Wybierz typ płatności..."
        />
        <CustomSelect
          value={paymentProvider}
          options={paymentProviders}
          loading={loadingPaymentProviders}
          setValue={setPaymentProvider}
          placeholder="Wybierz dostawcę płatności..."
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        <div style={{ width: '100%' }}>
          <CustomLineChart
            loadingData={loadingPaymentsLineChartData}
            data={paymentsLineChartData}
            xAxisKey={xAxis}
            dataKeys={keys}
            keysTranslation={keysTranslation}
            keysColors={colors}
            withBrush={false}
            valueFormatter={(value, name, params) => [
              `${value} ( ${params.payload.percent} )`,
              name,
            ]}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        <div style={{ width: '100%' }}>
          <CustomLineChart
            loadingData={loadingPaymentsLineChartData}
            data={paymentsLineChartData}
            xAxisKey={xAxis}
            dataKeys={['percentRaw']}
            keysTranslation={['Udział']}
            keysColors={colors}
            withBrush={false}
            valueFormatter={(value, name) => [`${value}%`, name]}
            domain={[0, 100]}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        <div style={{ width: '100%' }}>
          <CustomLineChart
            loadingData={loadingPaymentsLineChartData}
            data={paymentsLineChartData}
            xAxisKey={xAxis}
            dataKeys={['moneyValue']}
            keysTranslation={['Przychody dla danego sposobu płatności']}
            keysColors={colors}
            withBrush={false}
            valueFormatter={(value, name) => [
              currencyFormatter.format(value),
              name,
            ]}
            domain={[0, 100]}
          />
        </div>
      </div>
      <div style={{ width: '50%', margin: 'auto' }}>
        <Filters>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
            onStartDateChange={setStartDate}
            showTime={false}
          />
        </Filters>
      </div>
    </div>
  );
};

export default inject('personaStore')(observer(PaymentLineChart));

PaymentLineChart.propTypes = {
  personaStore: PropTypes.shape({
    paymentsLineChartData: PropTypes.array,
    availableMasks: PropTypes.array,
    paymentProviders: PropTypes.array,
    paymentTypes: PropTypes.array,
    fetchPaymentsLineChartData: PropTypes.func,
    fetchMasks: PropTypes.func,
    fetchPaymentProviders: PropTypes.func,
    fetchPaymentTypes: PropTypes.func,
    loadingPaymentsLineChartData: PropTypes.bool,
    loadingAvailableMasks: PropTypes.bool,
    loadingPaymentProviders: PropTypes.bool,
    loadingPaymentTypes: PropTypes.bool,
    clearPaymentsLineChartData: PropTypes.func,
  }),
  shortcutFrom: PropTypes.any,
  shortcutTo: PropTypes.any,
};
