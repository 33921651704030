import { action, observable } from 'mobx';
import config from '../config';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';
import moment from 'moment';
import 'moment/locale/pl';
import aggregationsStore from './aggregationsStore';
import { stringify } from 'query-string';
import qs from 'qs';

const DATE_LOCALE = 'pl';
const DATE_FORMAT_SHORT = 'll';
const DATE_FORMAT_LONG = 'lll';
const DATE_FORMAT_CUSTOM = 'DD MMM YYYY';

export class UsersStore {
  GENDERS = ['', 'Mężczyzni', 'Kobiety'];
  limit = 100;

  @observable activePushPercent = 0;
  @observable vouchersPercent = 0;

  @observable loadingChurnData = true;
  @observable churnData = [];

  @observable usersBarChartData = [];
  @observable loadingUsersBarChartData = true;

  @observable usersByMediumBarChartData = [];
  @observable usersByMediumBarChartDataMobile = {};
  @observable loadingUsersByMediumBarChartData = false;
  @observable loadingUsersByMediumBarChartDataMobile = {};

  @observable usersPieChartData = [];
  @observable usersPieChartDataTotal = 0;
  @observable loadingUsersPieChartData = true;

  @observable activePushData = [];
  @observable loadingPushData = true;

  @observable userEvents = [];
  @observable userEventsCount = 0;
  @observable loadingUserEventsData = true;
  @observable purchaseTotalPlnSum = 0;
  @observable chosenAction = '';

  @observable actions = [];
  @observable loadingActions = true;

  @observable weatherHints = [];
  @observable isLoadingWeatherHints = false;
  @observable availableMasks = [];
  @observable isLoadingAvailableMasks = false;

  @observable isLoadingMasksForConsents = true;
  @observable availableMasksForConsents = [];

  @observable consentsData = [];
  @observable isLoadingConsentsData = true;

  @observable couponPoolUsage = null;

  @observable surveysData = [];
  @observable isLoadingSurveysData = true;

  @observable surveyPageviewsMasks = [];
  @observable isLoadingMasksForSurveysPageviews = false;

  @observable surveys = [];
  @observable isLoadingSurveys = true;

  @observable surveyStatistics = [];
  @observable isLoadingSurveyStatistics = false;
  @observable surveysForStatisticsObj = {};

  @observable uniqueUsersData = [];
  @observable isLoadingUniqueUsersData = true;
  @observable availableMasksForUniqueUsers = [];
  @observable isLoadingMasksForUniqueUsers = true;

  @observable pieChartError = null;

  constructor() {}

  @action
  fetchUsersBarChartData = async (timeFrom, timeTo, step) => {
    this.loadingUsersBarChartData = true;
    const {
      data: { sum: data },
    } = await aggregationsStore.fetchStatisticsSum(
      timeFrom,
      timeTo,
      step,
      config.statIds.uniqueUsersAtLeast3eventsByMedium,
      '$fromTs',
    );

    this.usersBarChartData = data.map(({ _id, sum }) => {
      return {
        fromTs: moment(_id)
          .locale(DATE_LOCALE)
          .format(DATE_FORMAT_LONG)
          .toString(),
        value: sum,
      };
    });
    this.loadingUsersBarChartData = false;
  };

  @action
  fetchBannerBarChartData = async (timeFrom, timeTo, step) => {
    this.loadingBannerBarChartData = true;
    const { data } = await aggregationsStore.fetchStatistics({
      timeFrom,
      timeTo,
      step,
      statId: config.statIds.bannerCount,
    });
    this.bannerBarChartData = data.map((stat) => {
      const date = moment(stat.fromTs);
      stat.fromTs = date
        .locale(DATE_LOCALE)
        .format(DATE_FORMAT_LONG)
        .toString();
      return stat;
    });
    this.loadingBannerBarChartData = false;
  };

  @action
  fetchUsersByMediumBarChartData = async (
    timeFrom,
    timeTo,
    step,
    medium,
    mediumPreFix,
  ) => {
    if (mediumPreFix) {
      this.loadingUsersByMediumBarChartDataMobile[medium] = true;
    } else {
      this.loadingUsersByMediumBarChartData = true;
    }

    let query = { timeFrom, timeTo, step };
    if (medium === 'all') {
      query.statId = config.statIds.uniqueUsersAtLeast3events;
    } else {
      query.statId = config.statIds.uniqueUsersAtLeast3eventsByMedium;
      query.medium = medium;
    }
    const { data } = await aggregationsStore.fetchStatistics(query);

    if (mediumPreFix) {
      this.usersByMediumBarChartDataMobile[medium] = data.map((stat) => {
        const date = moment(stat.fromTs);
        stat.fromTs = date
          .locale(DATE_LOCALE)
          .format(DATE_FORMAT_LONG)
          .toString();
        return stat;
      });
      this.loadingUsersByMediumBarChartDataMobile[medium] = false;
    } else {
      this.usersByMediumBarChartData = data;
      this.loadingUsersByMediumBarChartData = false;
    }
  };

  @action
  fetchAndCountActivePushPercent = async (timeFrom, timeTo, step) => {
    const {
      data: { sum: activePushCount },
    } = await aggregationsStore.fetchStatisticsSum(
      timeFrom,
      timeTo,
      step,
      config.statIds.activePushCount,
    );
    const {
      data: { sum: usersCount },
    } = await aggregationsStore.fetchStatisticsSum(
      timeFrom,
      timeTo,
      step,
      config.statIds.usersCount,
    );
    this.activePushPercent =
      usersCount > 0 ? (activePushCount / usersCount).toFixed(2) : 0;
  };

  @action
  fetchAndCountVoucherPurchasePercent = async (timeFrom, timeTo, step) => {
    const {
      data: { sum: voucherCount },
    } = await aggregationsStore.fetchStatisticsSum(
      timeFrom,
      timeTo,
      step,
      config.statIds.purchasesWithVoucherCount,
    );
    const {
      data: { sum: purchaseCount },
    } = await aggregationsStore.fetchStatisticsSum(
      timeFrom,
      timeTo,
      step,
      config.statIds.purchasesCount,
    );
    this.vouchersPercent =
      purchaseCount > 0 ? (voucherCount / purchaseCount).toFixed(2) : 0;
  };

  @action
  fetchUsersPieChartData = async (timeFrom, timeTo) => {
    this.loadingUsersPieChartData = true;
    this.usersPieChartDataTotal = 0;
    this.usersPieChartData = [];
    this.pieChartError = null;

    const query = {
      timeFrom: moment(timeFrom).startOf('day').toISOString(),
      timeTo: moment(timeTo).endOf('day').toISOString(),
      groupBy: 'medium',
      dataSource: 'really_unique_devices_daily',
    };

    try {
      let { data } = await API.get(
        `${API_ROUTES.UNIQUE_DEVICES}?${qs.stringify(query)}`,
      );
      let total = 0;
      data = data.filter((item) => item._id !== 'pushpushgo');
      data.forEach(({ uniqueDevices }) => (total += uniqueDevices));
      data.sort((a, b) => a._id.localeCompare(b._id));

      this.usersPieChartData = data.map(({ _id: medium, uniqueDevices }) => ({
        medium,
        value: uniqueDevices,
        total,
      }));
      this.usersPieChartDataTotal = total;
      this.loadingUsersPieChartData = false;
    } catch {
      this.loadingUsersPieChartData = false;
      this.pieChartError =
        'Przekroczenie limitu danych, proszę spróbować ponownie, wybierając mniejszy zakres dat.';
    }
  };

  @action
  fetchChurnData = async (timeFrom, timeTo) => {
    const timeFromParsed = moment(timeFrom).startOf('day').toISOString();
    const timeToParsed = moment(timeTo)
      .add(1, 'day')
      .startOf('day')
      .toISOString();
    this.loadingChurnData = true;
    const queryChurn = {
      statId: config.statIds.churnAtLeast3events,
      timeFrom: timeFromParsed,
      timeTo: timeToParsed,
      step: '1D',
    };

    const queryAllDevices = {
      statId: config.statIds.monthDevicesDistinct3Events,
      timeFrom: moment(timeFromParsed).subtract(31, 'days').toISOString(),
      timeTo: moment(timeToParsed).subtract(31, 'days').toISOString(),
      step: '1D',
    };

    const { data: churn } = await aggregationsStore.fetchStatistics(queryChurn);
    const { data: all } = await aggregationsStore.fetchStatistics(
      queryAllDevices,
    );

    const churnObject = {};
    churn.forEach((item) => {
      churnObject[item.fromTs] = item.value;
    });

    this.churnData = all.map((item) => {
      const fromTs = moment(item.fromTs).add(31, 'days').toISOString();
      item.lost = churnObject[fromTs] ? churnObject[fromTs] : 0;
      item.churn = item.value > 0 ? item.lost / item.value : 0;
      item.churnPercent = item.churn * 100;
      item.fromTs = moment(fromTs)
        .locale(DATE_LOCALE)
        .format(DATE_FORMAT_CUSTOM)
        .toString();
      return item;
    });

    this.loadingChurnData = false;
  };

  @action fetchUserEvents = async (query) => {
    this.userEvents = [];
    this.loadingUserEventsData = true;
    const { events, eventsCount } = await aggregationsStore.fetchEvents(query);
    this.userEvents = events;
    this.userEventsCount = eventsCount;
    if (query.couponPool) {
      this.couponPoolUsage = eventsCount;
    } else {
      this.couponPoolUsage = null;
    }
    this.loadingUserEventsData = false;
  };

  @action fetchActions = async () => {
    this.actions = [];
    this.loadingActions = true;

    try {
      const { data } = await API.get(API_ROUTES.ACTIONS);
      this.actions = data.map((action) => ({
        label: action.split('_').join(' '),
        value: action,
      }));
    } catch (e) {
      console.error(e);
    }
    this.loadingActions = false;
  };

  @action setChosenAction = (action) => {
    this.chosenAction = action;
  };

  @action fetchPurchaseTotalPln = async (query) => {
    try {
      const { data } = await API.get(
        `${API_ROUTES.PURCHASE_PLN_TOTAL}?${stringify(query)}`,
      );
      this.purchaseTotalPlnSum = data;
    } catch (e) {
      console.error(e);
    }
  };

  @action
  fetchPushData = async (timeFrom, timeTo, step) => {
    this.loadingPushData = true;

    const query = {
      timeFrom,
      timeTo,
      step: step || '1D',
    };
    const pushStat = config.statIds.activePPGCount;
    const usersStat = config.statIds.uniqueUsersAtLeast3eventsByMedium;

    if (!timeFrom || !timeTo) {
      query.timeFrom = moment().subtract(30, 'days').toISOString();
      query.timeTo = moment().toISOString();
    }

    const { data: pushData } = await aggregationsStore.fetchStatistics({
      ...query,
      statId: pushStat,
    });

    const {
      data: { sum: usersData },
    } = await aggregationsStore.fetchStatisticsSum(
      query.timeFrom,
      query.timeTo,
      query.step,
      usersStat,
      '$fromTs',
    );

    const usersObject = {};
    usersData.forEach((item) => {
      usersObject[item._id] = item.sum;
    });

    this.activePushData = pushData.map((item) => {
      item.usersCount = usersObject[item.fromTs];
      item.pushCount = item.value;
      item.percent =
        item.usersCount > 0 ? (item.pushCount / item.usersCount) * 100 : 0;
      item.fromTs = moment(item.fromTs)
        .locale(DATE_LOCALE)
        .format(DATE_FORMAT_CUSTOM)
        .toString();
      return item;
    });

    this.loadingPushData = false;
  };

  @action
  fetchWeatherHints = async ({ from, to, mask }) => {
    this.isLoadingWeatherHints = true;

    const query = {
      statId: config.statIds.weatherHints,
      step: '1D',
      timeFrom: moment(from).startOf('day').toISOString(),
      timeTo: moment(to).endOf('day').toISOString(),
      groupingKey: 'object_id,category_id',
      aggregateKey: '$value',
      limit: this.limit,
      store: mask,
    };

    try {
      let { data: data } = await API.get(
        `${API_ROUTES.TOP_WEATHER_HINTS}?${qs.stringify(query)}`,
      );

      this.weatherHints = data.map(
        (
          {
            _id: { object_id: analyticsId },
            value,
            hintMessage,
            categoryName,
            categoryPath,
          },
          index,
        ) => ({
          analyticsId,
          hintMessage,
          categoryName,
          categoryPath,
          value,
          position: index + 1,
        }),
      );
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoadingWeatherHints = false;
    }
  };

  @action
  fetchMasks = async ({ from, to }) => {
    this.loadingAvailableMasks = true;
    this.availableMasks = [];

    const currentMonthQuery = {
      statId: config.statIds.weatherHints,
      step: '1D',
      timeFrom: moment(from).startOf('day').toISOString(),
      timeTo: moment(to).endOf('day').toISOString(),
      key: 'store',
    };

    const { data } = await API.get(
      `${API_ROUTES.STATISTIC_DISTINCT_KEY}?${qs.stringify(currentMonthQuery)}`,
    );
    this.availableMasks = data.map((mask) => ({ label: mask, value: mask }));
    this.loadingAvailableMasks = false;
  };

  @action
  fetchMasksForConsents = async ({ from, to }) => {
    this.isLoadingMasksForConsents = true;
    this.availableMasksForConsents = [];

    const currentMonthQuery = {
      statId: config.statIds.consentScreenDisplayedCount,
      step: '1D',
      timeFrom: moment(from).startOf('day').toISOString(),
      timeTo: moment(to).endOf('day').toISOString(),
      key: 'store',
    };

    const { data } = await API.get(
      `${API_ROUTES.STATISTIC_DISTINCT_KEY}?${qs.stringify(currentMonthQuery)}`,
    );

    this.availableMasksForConsents = ['Wszystkie', ...data];
    this.isLoadingMasksForConsents = false;
  };

  @action
  fetchConsentsChartData = async (fromDate, toDate, chosenMask) => {
    this.consentsData = [];
    this.isLoadingConsentsData = true;

    const params = {};

    if (chosenMask && chosenMask !== 'Wszystkie') {
      params.store = chosenMask;
    }

    const allDisplayedQuery = {
      statId: config.statIds.consentScreenDisplayedCount,
      step: '1D',
      key: '$fromTs',
      timeFrom: moment().startOf('month').toISOString(),
      timeTo: moment().toISOString(),
      params,
    };

    const allSelectTrue = {
      statId: config.statIds.consentSelectAllCount,
      step: '1D',
      key: '$fromTs',
      timeFrom: moment().startOf('month').subtract(1, 'month').toISOString(),
      timeTo: moment().subtract(1, 'month').toISOString(),
      params: { ...params, active: true },
    };

    if (fromDate && toDate) {
      allDisplayedQuery.timeFrom = moment(fromDate).toString();
      allDisplayedQuery.timeTo = moment(toDate).toString();
      allSelectTrue.timeTo = moment(toDate).toString();
      allSelectTrue.timeTo = moment(toDate).toString();
    }

    let {
      data: { sum: displayed },
    } = await aggregationsStore.fetchStatisticsSum(
      allDisplayedQuery.timeFrom,
      allDisplayedQuery.timeTo,
      allDisplayedQuery.step,
      allDisplayedQuery.statId,
      allDisplayedQuery.key,
      { params: allDisplayedQuery.params },
    );

    let {
      data: { sum: allSelected },
    } = await aggregationsStore.fetchStatisticsSum(
      allSelectTrue.timeFrom,
      allSelectTrue.timeTo,
      allSelectTrue.step,
      allSelectTrue.statId,
      allSelectTrue.key,
      { params: allSelectTrue.params },
      allSelectTrue.sumKey,
    );

    const allSelectedObj = {};
    allSelected.forEach((stat) => {
      allSelectedObj[stat._id] = stat.sum;
    });

    this.consentsData = displayed.map((stat) => {
      const date = moment(stat._id);
      stat.fromTs = date
        .locale(DATE_LOCALE)
        .format(DATE_FORMAT_SHORT)
        .toString();
      stat.displayed = stat.sum;
      stat.allSelected = allSelectedObj[stat._id] || 0;
      return stat;
    });

    this.isLoadingConsentsData = false;
  };

  @action
  fetchMasksForSurveys = async ({ from, to, pageviews }) => {
    let dataKey = 'surveyAnswersMasks';
    let loadingKey = 'isLoadingMasksForSurveysAnswers';
    let statId = config.statIds.questionnaireAnswers;
    if (pageviews) {
      dataKey = 'surveyPageviewsMasks';
      loadingKey = 'isLoadingMasksForSurveysPageviews';
      statId = config.statIds.questionnairePageViews;
    }
    this[loadingKey] = true;
    this[dataKey] = [];

    const currentMonthQuery = {
      statId,
      step: '1D',
      timeFrom: moment(from).startOf('day').toISOString(),
      timeTo: moment(to).endOf('day').toISOString(),
      key: 'store',
    };

    const { data } = await API.get(
      `${API_ROUTES.STATISTIC_DISTINCT_KEY}?${qs.stringify(currentMonthQuery)}`,
    );

    this[dataKey] = ['Wszystkie', ...data];
    this[loadingKey] = false;
  };

  @action
  fetchSurveysChartData = async (fromDate, toDate, chosenMask, survey) => {
    this.surveysData = [];
    this.isLoadingSurveysData = true;

    let params = null;
    if (chosenMask && chosenMask !== 'Wszystkie') {
      params = {
        params: { store: chosenMask },
      };
    }

    if (survey) {
      if (params) {
        params.params.object_id = survey.value;
      } else {
        params = {
          params: { object_id: survey.value },
        };
      }
    }

    const query = {
      statId: config.statIds.questionnairePageViews,
      step: '1D',
      timeFrom: moment(fromDate).toISOString(),
      timeTo: moment(toDate).toISOString(),
      key: '$fromTs',
      params,
    };

    const { data } = await aggregationsStore.fetchStatisticsSum(
      query.timeFrom,
      query.timeTo,
      query.step,
      query.statId,
      query.key,
      query.params,
      null,
    );

    this.surveysData = data.sum.map((stat) => {
      stat._id = moment(stat._id)
        .locale(DATE_LOCALE)
        .format(DATE_FORMAT_SHORT)
        .toString();
      return stat;
    });
    this.isLoadingSurveysData = false;
  };

  fetchSurveys = async (surveyId) => {
    this.isLoadingSurveys = true;
    this.surveys = [];
    try {
      let { data } = await API.get(
        `${API_ROUTES.SURVEYS}?${qs.stringify({ surveyId })}`,
      );

      data = data.map(({ survey_id, title }) => ({
        value: survey_id,
        label: title,
      }));

      data.sort((a, b) => a.value - b.value);

      this.surveys = data;
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoadingSurveys = false;
    }
  };

  @action
  fetchSurveyStatistics = async ({ from, to, mask, surveyId }) => {
    this.isLoadingSurveyStatistics = true;
    try {
      const query = {
        statId: config.statIds.questionnaireAnswers,
        step: '1H',
        timeFrom: moment(from).toISOString(),
        timeTo: moment(to).toISOString(),
        groupingKey: 'store,answer_id,questionnaire_id,question_id',
        aggregateKey: '$value',
        limit: 1000,
        params: {},
      };

      if (mask) {
        query.params.store = mask;
      }
      if (surveyId) {
        query.params.questionnaire_id = surveyId;
      }

      const { data: statistics } = await API.get(
        `${API_ROUTES.STATISTICS_TOP}?${qs.stringify(query)}`,
      );

      if (Object.keys(this.surveysForStatisticsObj).length === 0) {
        const { data: surveys } = await API.get(API_ROUTES.SURVEYS);
        const surveysObj = {};

        surveys.forEach((survey) => {
          const questionsObj = {};
          survey.questions.forEach((question) => {
            const answersObj = {};
            question.answers.forEach((answer) => {
              answersObj[answer.id] = answer;
            });
            question.answers = answersObj;
            questionsObj[question.id] = question;
          });
          survey.questions = questionsObj;
          surveysObj[survey.survey_id] = survey;
        });

        this.surveysForStatisticsObj = surveysObj;
      }

      const combinedData = [];
      statistics.forEach((stat) => {
        const {
          store,
          answer_id: answerId,
          question_id: questionId,
          questionnaire_id: surveyId,
        } = stat._id;

        const survey = this.surveysForStatisticsObj[surveyId];

        if (
          survey &&
          survey.store === store &&
          survey.questions[questionId] &&
          survey.questions[questionId].answers[answerId]
        ) {
          const question = survey.questions[questionId];
          const answer = question.answers[answerId];
          combinedData.push({
            store,
            surveyName: survey.title,
            questionTitle: question.title,
            answer: answer.title,
            value: stat.value,
          });
        }
      });

      this.surveyStatistics = combinedData;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingSurveyStatistics = false;
    }
  };

  @action
  fetchUniqueUsersChartData = async (fromDate, toDate, chosenMask) => {
    this.uniqueUsersData = [];
    this.isLoadingUniqueUsersData = true;

    const params = {};

    if (chosenMask && chosenMask !== 'Wszystkie') {
      params.store = chosenMask;
    }

    const allDisplayedQuery = {
      statId: config.statIds.reallyUniqueUsersDaily,
      step: '1D',
      key: '$fromTs',
      timeFrom: moment().startOf('month').toISOString(),
      timeTo: moment().toISOString(),
      params,
    };

    if (fromDate && toDate) {
      allDisplayedQuery.timeFrom = moment(fromDate).toString();
      allDisplayedQuery.timeTo = moment(toDate).toString();
    }

    let {
      data: { sum: result },
    } = await aggregationsStore.fetchStatisticsSum(
      allDisplayedQuery.timeFrom,
      allDisplayedQuery.timeTo,
      allDisplayedQuery.step,
      allDisplayedQuery.statId,
      allDisplayedQuery.key,
      { params: allDisplayedQuery.params },
    );

    this.uniqueUsersData = result.map((stat) => {
      stat._id = moment(stat._id).locale('pl').format('ll');
      return stat;
    });
    this.isLoadingUniqueUsersData = false;
  };

  @action
  fetchMasksForUniqueUsers = async ({ from, to }) => {
    this.isLoadingMasksForUniqueUsers = true;
    this.availableMasksForUniqueUsers = [];

    const currentMonthQuery = {
      statId: config.statIds.reallyUniqueUsersDaily,
      step: '1D',
      timeFrom: moment(from).startOf('day').toISOString(),
      timeTo: moment(to).endOf('day').toISOString(),
      key: 'store',
    };

    let { data } = await API.get(
      `${API_ROUTES.STATISTIC_DISTINCT_KEY}?${qs.stringify(currentMonthQuery)}`,
    );

    data = data.filter((item) => item !== null);

    this.availableMasksForUniqueUsers = ['Wszystkie', ...data];
    this.isLoadingMasksForUniqueUsers = false;
  };
}

export default new UsersStore();
