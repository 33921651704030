import React from 'react';

import Select from 'react-select';
import { inject, observer } from 'mobx-react';
import * as PropTypes from 'prop-types';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import Loader from '../../../components/Loader/Loader';
import { DatePickerContainer } from '../../ProductsPage/Components/ProductsMaskSelect';

function MaskSelectDateRange({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  loadingMasks,
  setMask,
  masks,
  chosenMask,
}) {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '50%' }}>
        <Select
          isLoading={loadingMasks}
          value={[chosenMask]}
          placeholder="Wybierz maski..."
          getOptionLabel={(option) => option}
          getOptionValue={(option) => option}
          name="colors"
          options={masks}
          styles={{
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          onChange={(option) => {
            setMask(option);
          }}
        />
      </div>
      <div style={{ width: '50%' }}>
        <DatePickerContainer
          style={{ justifyContent: 'space-between', margin: 1 }}
        >
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
            onStartDateChange={setStartDate}
            showTime={false}
          />
        </DatePickerContainer>
      </div>
      <div>{loadingMasks && <Loader py={0} />}</div>
    </div>
  );
}

export default inject('bannersStore')(observer(MaskSelectDateRange));

MaskSelectDateRange.propTypes = {
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  setStartDate: PropTypes.any,
  setEndDate: PropTypes.any,
  availableMasksDateRange: PropTypes.array,
  loadingMasks: PropTypes.bool,
  setMask: PropTypes.func,
  masks: PropTypes.array,
  chosenMask: PropTypes.string,
};
