import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import * as PropTypes from 'prop-types';
import CustomLineChart from '../../../components/CustomLineChart/CustomLineChart';
import moment from 'moment';
import MaskSelectDateRange from '../../SalesPage/Components/MaskSelectDateRange';

const colors = ['#272727', '#868686'];
const keys = ['sum'];
const keysTranslation = ['użytkownicy'];
const xAxis = '_id';

const UsersLineChart = ({ usersStore }) => {
  const {
    isLoadingUniqueUsersData,
    uniqueUsersData,
    fetchUniqueUsersChartData,
    fetchMasksForUniqueUsers,
    isLoadingMasksForUniqueUsers,
    availableMasksForUniqueUsers,
  } = usersStore;
  const [startDate, setStartDate] = useState(
    moment().startOf('month').toDate(),
  );
  const [endDate, setEndDate] = useState(moment().toDate());
  const [chosenMask, setChosenMask] = useState('Wszystkie');
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (startDate && endDate && isMounted) {
      fetchUniqueUsersChartData(startDate, endDate, chosenMask);
      fetchMasksForUniqueUsers({ from: startDate, to: endDate });
    }
  }, [startDate, endDate, chosenMask, isMounted]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <>
      <CustomLineChart
        loadingData={isLoadingUniqueUsersData}
        data={uniqueUsersData}
        xAxisKey={xAxis}
        dataKeys={keys}
        keysTranslation={keysTranslation}
        keysColors={colors}
      />
      <div style={{ width: '50%', margin: 'auto' }}>
        <MaskSelectDateRange
          chosenMask={chosenMask}
          startDate={startDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          masks={availableMasksForUniqueUsers}
          loadingMasks={isLoadingMasksForUniqueUsers}
          setMask={setChosenMask}
        />
      </div>
    </>
  );
};
export default inject('usersStore')(observer(UsersLineChart));

UsersLineChart.propTypes = {
  usersStore: PropTypes.shape({
    isLoadingUniqueUsersData: PropTypes.bool,
    uniqueUsersData: PropTypes.array,
    fetchUniqueUsersChartData: PropTypes.func,
    fetchMasksForUniqueUsers: PropTypes.func,
    isLoadingMasksForUniqueUsers: PropTypes.bool,
    availableMasksForUniqueUsers: PropTypes.array,
  }),
};
