export const API_ROUTES = {
  STATISTICS: '/statistics.json',
  STATISTICS_SUM: '/statistics-sum.json',
  STATISTICS_TOP: '/statistics-top.json',
  STATISTICS_AVG: '/statistics-avg.json',
  EVENTS: '/events.json',
  ACTIONS: '/actions.json',
  STORES: '/stores.json',
  COUNTRIES: '/countries.json',
  PURCHASE_PLN_TOTAL: '/purchase-pln-total.json',
  PURCHASE: {
    HOURLY: '/purchase/hourly.json',
  },
  STATISTICS_TOP_PRODUCTS: '/statistics-top-products.json',
  STATISTICS_TOP_BANNERS: '/statistics-top-banners.json',
  TOP_CATEGORIES_BY_MASK: '/top-categories-by-mask.json',
  TOP_PRODUCTS_CATEGORIES: '/top-products-by-category.json',
  TOP_PRODUCTS_BY_MASK: '/top-products-by-mask.json',
  STATISTIC_DISTINCT_KEY: '/statistics-distinct-key.json',
  UNIQUE_USERS: 'statistics-uniq-users.json',
  BANNERS: 'banners.json',
  TOP_NOTIFICATIONS: '/top-notifications-by-mask-and-type.json',
  TOP_WEATHER_HINTS: '/top-weather-hints.json',
  COUPON_POOLS: '/promo-codes/pools/coupons.json',
  ACTIVATION_CODE_POOLS: '/promo-codes/pools/activation-codes.json',
  LANDING_PAGES: 'landing-pages.json',
  SURVEYS: 'surveys.json',
  UNIQUE_DEVICES: 'unique-devices.json',
};
export const APP_ROUTES = {
  HOME: '/',
  USERS: '/users',
  SALES: '/sales',
  PRODUCTS: '/products',
  DISCOUNTS: '/discounts',
  PERSON: '/person',
  BANNERS: '/banners',
  STORES: '/stores',
  WISHLIST: '/wishlist',
  CUSTOMER_JOURNEY: '/customer-journey',
  LOGIN: '/login',
  ERROR: '/error',
  ELEMENTS: '/elements',
  PURCHASE: '/purchase',
  NOTIFICATIONS: '/notifications',
  LANDING_PAGES: '/landing-pages',
  RECOMMENDATIONS: '/recommendations',
};
