import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import TableWithJson from '../../../components/TableWithJson/TableWithJson';
import { Filters } from '../../../components/helpers/chartHelpers';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';

const columns = [
  { id: 'position', label: 'Pozycja', minWidth: 100 },
  { id: 'value', label: 'Ilość', minWidth: 100 },
  { id: '_id', label: 'SKU', minWidth: 100 },
  { id: 'name', label: 'Nazwa produktu', minWidth: 100 },
  { id: 'regularPrice', label: 'Cena regularna', minWidth: 100 },
  { id: 'currency', label: 'Waluta', minWidth: 100 },
];

function TopWishlistProductsGlobalTable({ wishlistStore }) {
  const {
    globalTopWishlist,
    loadingGlobalTopWishlist,
    startDate,
    endDate,
    setEndDate,
    setStartDate,
    fetchAll,
  } = wishlistStore;

  useEffect(async () => {
    await fetchAll(startDate, endDate);
  }, [startDate, endDate]);

  return (
    <>
      <div style={{ width: '50%', margin: 'auto' }}>
        <Filters>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
            onStartDateChange={setStartDate}
            showTime={false}
          />
        </Filters>
      </div>
      <TableWithJson
        columns={columns}
        data={globalTopWishlist}
        loadingData={loadingGlobalTopWishlist}
        withPagination={false}
        showJson={false}
      />
    </>
  );
}

export default inject('wishlistStore')(
  observer(TopWishlistProductsGlobalTable),
);

TopWishlistProductsGlobalTable.propTypes = {
  wishlistStore: PropTypes.shape({
    globalTopWishlist: PropTypes.array,
    loadingGlobalTopWishlist: PropTypes.bool,
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    setStartDate: PropTypes.func,
    setEndDate: PropTypes.func,
    fetchAll: PropTypes.func,
  }),
};
